<template>
  <v-card
    elevation="0"
    class="markdown__card">
    <vue-showdown
      class="markdown__file"
      :markdown="source"/>
  </v-card>
</template>

<script>
export default {
  name: 'CyMarkdownCard',
  props: {
    sourceText: {
      type: Object,
      required: true,
    },
  },
  computed: {
    source () {
      return this.sourceText[this.$i18n.locale]
    },
  },
}
</script>

<style lang="scss" scoped>
.markdown__file {
  padding: 32px;

  ::v-deep {
    blockquote,
    h1,
    h2,
    h3,
    h4,
    p,
    ul {
      margin-top: 16px;
      margin-bottom: 0;
    }

    h1,
    h2,
    h3,
    h4 {
      font-weight: $font-weight-bold;
      line-height: $line-height-headings;
    }

    h1 {
      margin-bottom: 20px;
      font-size: map.get($font-sizes, "xl");

      &:first-child {
        margin-top: 0;
      }
    }

    h2 {
      padding-top: 20px;
      border-top: 1px solid get-color("primary", "light-4");
      font-size: map.get($font-sizes, "xxl");
    }

    h3 {
      margin-top: 32px;
      font-size: map.get($font-sizes, "xl");
    }

    h4 {
      font-size: map.get($font-sizes, "lg");
    }

    a {
      color: get-color("secondary");
    }
  }
}

.markdown__card {
  max-width: 700px;
  margin: 60px auto;
  padding-right: 40px;
  padding-left: 40px;
}
</style>
