
import termsAndConditionsEN from './termsAndConditions-en.md'
import termsAndConditionsES from './termsAndConditions-es.md'
import termsAndConditionsFR from './termsAndConditions-fr.md'
import privacyPolicyEN from './privacyPolicy-en.md'
import privacyPolicyES from './privacyPolicy-es.md'
import privacyPolicyFR from './privacyPolicy-fr.md'

export const termsAndConditions = {
  en: termsAndConditionsEN,
  es: termsAndConditionsES,
  fr: termsAndConditionsFR,
}

export const privacyPolicy = {
  en: privacyPolicyEN,
  es: privacyPolicyES,
  fr: privacyPolicyFR,
}

export const testingStacks = {
  privacyPolicyEN,
}
